<template>
    <v-card  class="rounded-lg transition-cubic overflow-hidden" :min-height="edit ? '70':''" style="position: relative">
        <v-img
                v-if="item.background[0]"
                :src="backend_url + item.background[0].__url__"
                min-height="70"
                :height="height + 34"
                class="overflow-hidden pa-4 image grey darken-4"
                style="align-items: center"
                :position="item.__position ? item.__position : '0px 0px'"
                @mousedown="(e) => {edit ? initPosition(e) : null}"
                @mouseup="edit ? mousedown = null : null"
                @mousemove="(e) => { mousedown !== null && edit ? moveBackground(e) : null }"
                @mouseleave="edit ? mousedown = null : null"
        >
            <HtmlArea ref="child" :edit="false" :content="item.content" :full="true" classes="white--text" style="pointer-events: none" />
        </v-img>
        <div v-else class="pa-4">
            <HtmlArea ref="child" :edit="false" :content="item.content" :full="true" />
        </div>
        <div style="position: absolute; top: 12px; right: 12px;">
            <v-btn
                    v-if="edit"
                    fab
                    small
                    class="float-right handle"
                    style="cursor: grab;"
                    icon
            >
                <v-icon>mdi-drag-horizontal-variant</v-icon>
            </v-btn>
            <v-btn
                    @click="$router.push('/dashboard/announcement/' + item.id)"
                    v-if="edit"
                    fab
                    small
                    class="float-right elevation-2 mr-2"
                    color="primary"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
                    @click="$emit('delete')"
                    v-if="edit"
                    fab
                    small
                    class="float-right elevation-2 mr-2"
                    color="error"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>
<script>
    export default {
        name: "Announcement",
        props: ['edit', 'item'],
        components: {
            HtmlArea: () => import('@/components/fields/HtmlArea.vue')
        },
        data() {
            return {
                height: 0,
                colors: ['red', 'pink', 'purple', 'blue', 'teal', 'green', 'orange', 'deep-orange'],
                mousedown: null,
                origin: null
            }
        },
        computed: {
            generatedColor(){
                return this.colors[Math.floor(Math.random() * this.colors.length)]
            },
            backend_url(){
				return this.$store.getters["config/backend_url"]
			}
        },
        watch: {
            mousedown(val, oldVal){
                if(val === null){
                    this.$set(this.item, '__position', oldVal.target.nextSibling.style.backgroundPosition)
                }
            }
        },
        methods: {
            initPosition(e){
                this.mousedown = e
                let array2 = this.mousedown.target.nextSibling.style.backgroundPosition.split(' ')
                this.origin = {x: parseInt(array2[0].replace('px', '')), y: parseInt(array2[1].replace('px', ''))}
            },
            moveBackground(e){
                let img_backgorund = e.target.nextSibling

                if(img_backgorund !== null){
                    let div = this.mousedown.target.getBoundingClientRect()
                    let delta = {x: e.clientX - this.mousedown.clientX + this.origin.x, y: e.clientY - this.mousedown.clientY + this.origin.y}

                    let array2 = img_backgorund.style.backgroundPosition.split(' ')
                    let current_pos = {x: parseInt(array2[0].replace('px', '')), y: parseInt(array2[1].replace('px', ''))}
                    let deltaY = e.clientY - this.mousedown.clientY

                    if(deltaY < 0 && -current_pos.y < div.height - img_backgorund.getBoundingClientRect().height)
                        img_backgorund.style.backgroundPosition = `${current_pos.x}px ${delta.y}px`
                    else if(deltaY > 0 && -current_pos.y - deltaY > 0)
                        img_backgorund.style.backgroundPosition = `${current_pos.x}px ${delta.y}px`
                }
            }
        },
        updated(){
            this.height = this.$refs.child.$el.clientHeight
        }
    }
</script>

<style scoped>
    .image >>> .v-responsive__content {
        pointer-events: none;
    }
</style>
